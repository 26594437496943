define("qrpay-frontend/mirage/routes/merchants", ["exports", "ember-cli-mirage/response"], function (_exports, _response) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /**
   * @param {Object} server - mirage server
   */
  function _default(server) {
    server.get('/merchants/:code', function (_ref, request) {
      var merchants = _ref.merchants;

      if (request.params.code.toUpperCase() === 'NNNNNN') {
        return new _response.default(400, {}, {
          error: {
            code: 'STORE_CLOSED',
            message: 'error.message'
          }
        });
      }

      var merchant = merchants.all().models.filter(function (m) {
        return m.code === request.params.code;
      })[0];
      return merchant || new _response.default(404);
    });
    server.get('/merchants/:code/checks', function (_ref2) {
      var checks = _ref2.checks;
      return checks.all();
    });
    server.post('/merchants/:code/checks/:id/authorize', function (_ref3) {
      var authorizes = _ref3.authorizes;
      return authorizes.all().models[0];
    });
    server.post('/merchants/:code/checks/:id/payment', function () {
      return new _response.default(200);
    });
    server.post('/merchants/:code/checks/:id/crypto-payment', function () {
      return new _response.default(200, {}, {
        status: 200,
        response: {
          uuid: "7e3a11b4-f90e-443f-b700-fece00d2b17b",
          checkoutUrl: 'http://localhost:4200/paid',
          expireAt: "2025-02-07T10:38:37.812Z"
        }
      });
    });
    server.put('/merchants/:code/loyalty/check/:checkId/member', function (_, request) {
      var _JSON$parse = JSON.parse(request.requestBody),
          loyaltyMemberPhoneNumber = _JSON$parse.loyaltyMemberPhoneNumber;

      var phoneNumberForError = '18142277097';
      var phoneNumberForInactivity = '18142277096'; //phone number for getting error in search

      if (loyaltyMemberPhoneNumber === phoneNumberForError) {
        return new _response.default(404, {});
      }

      if (loyaltyMemberPhoneNumber === phoneNumberForInactivity) {
        return new _response.default(400, {}, {
          error: {
            code: 'LOYALTY_MEMBER_NOT_ACTIVE',
            message: 'Your loyalty account is deactivated. Please contact restaurant'
          }
        });
      }

      return new _response.default(204);
    });
    server.get('/merchants/:code/loyalty/member/:loyaltyMemberGuid', function (_, request) {
      if (request.params.loyaltyMemberGuid === 'loyalty-member-guid-1') {
        return {
          loyaltyMember: {
            currentBalance: '1000',
            guid: 'loyalty-member-guid-1'
          }
        };
      }

      return {
        loyaltyMember: {
          currentBalance: '2000',
          guid: 'guid'
        }
      };
    });
    server.delete('/merchants/:code/loyalty/check/:checkId/discount', function () {
      return new _response.default(204);
    });
    server.delete('/merchants/:code/loyalty/check/:checkId/member', function () {
      return new _response.default(204);
    });
    server.put('/merchants/:code/loyalty/check/:checkId/discount', function () {
      return new _response.default(204);
    });
    server.post('/merchants/:code/loyalty/member', function () {
      return new _response.default(204);
    });
  }
});