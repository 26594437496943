define("qrpay-frontend/templates/components/credit-card-i4go-iframe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F9Ff8MnW",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"i4goFrame\"],[12],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,1,\"threeDsModal\"],[15,0,[31,[\"modal \",[30,[36,0],[[32,0,[\"loadThreeDsOtp\"]],\"displayThreeDsOtp\"],null]]]],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"modal-content\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[14,1,\"threeDsFrame\"],[14,0,\"threeDsFrame\"],[12],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "qrpay-frontend/templates/components/credit-card-i4go-iframe.hbs"
    }
  });

  _exports.default = _default;
});