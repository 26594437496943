define("qrpay-frontend/mirage/factories/authorize", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable max-len */
  var _default = _emberCliMirage.Factory.extend({
    accessBlock: 'A0000,B338E2AB210D2EF333E09D6B4B7866CAE2E0AD42E3DDFA2AD59AE0C3FAAC6B53AAB31EA0F86C387169E129917954FC36EC84284083FD02C6E130B803210330245AD61D3E8B7875DD01BDE384B93A65C2943640EE7BFFF1EAAFCFA0D1D934312C214EAA6228BB6DDAE42059E7AA5EFDF065FD90A4926A39CBE2244E3415350D091D33F19D1F3DF105BCCDCCEC0C0BE9713BFE088BDC1084342F3E5334F1E12EB889578D66A4DF68B1712C9489BD525734EB21C07B0F442343EC15F555CE6B47616E3C7476ED9299FC86E0CCE38A651A8D8ADB30DB1AF4A0EB1C340C17C4F856B02E63BD1AB7C6E06D4F9D53F9B3CABF1B',
    server: 'https://wh-i4go.s4-test.com',
    invoiceNumber: '0103001693'
  });

  _exports.default = _default;
});