define("qrpay-frontend/mirage/factories/merchant", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    code: 'AAAAAA',
    logoUrl: 'https://via.placeholder.com/500x250.png?text=Logo',
    name: 'Uncle Moe\'s Family Feed Bag',
    isDineInEnabled: true,
    dineInUrl: 'https://online.skytab.com/AAAAAA?dineIn=true',
    isQRPayEnabled: true,
    isLoyaltyEnabled: true,
    labelPrompt: 'Your Car Make',
    currency: 'USD',
    countryCode: 'US',
    isCryptoPaymentEnabled: true,
    tips: function tips() {
      return {
        includeTaxInTipSuggestion: false,
        predefinedTipPercentage1: 1800,
        predefinedTipPercentage2: 2000,
        predefinedTipPercentage3: 2500,
        preselectedTip: 1,
        areAllowed: true
      };
    }
  });

  _exports.default = _default;
});