define("qrpay-frontend/templates/paid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HQtKY1V/",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"footer-page\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[14,0,\"d-flex flex-column flex-grow-1 justify-content-center align-items-center\"],[12],[2,\"\\n\\t\\t\"],[8,\"alert/check\",[],[[],[]],null],[2,\"\\n\\t\\t\"],[8,\"alert/title\",[],[[\"@title\"],[[31,[\"Paid \",[30,[36,2],[[30,[36,1],[[32,1,[\"paid\"]],0],null],[30,[36,0],[[32,1,[\"paid\"]],[32,1,[\"currency\"]],[32,1,[\"country\"]]],null]],null]]]]],null],[2,\"\\n\\t\\t\"],[8,\"alert/description\",[],[[\"@description\"],[[32,1,[\"successMessage\"]]]],null],[2,\"\\n\\t\"],[13],[2,\"\\n\\t\"],[8,\"footer-banner\",[],[[\"@isDisplayed\"],[true]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"currency-format\",\"gt\",\"if\"]}",
    "meta": {
      "moduleName": "qrpay-frontend/templates/paid.hbs"
    }
  });

  _exports.default = _default;
});