define("qrpay-frontend/templates/components/check/proceed-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CocZ8my8",
    "block": "{\"symbols\":[\"@isSubmitDisabled\",\"@pay\"],\"statements\":[[10,\"div\"],[14,0,\"d-flex m-1 p-2 mb-2\"],[12],[2,\"\\n\\t\"],[11,\"button\"],[24,0,\"btn btn-primary btn-block\"],[16,\"disabled\",[32,1]],[24,4,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,2]],null]],null],[12],[2,\"\\n\\t\\tProceed to Payment\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "qrpay-frontend/templates/components/check/proceed-button.hbs"
    }
  });

  _exports.default = _default;
});